<template>
  <div>
    <template v-if="bsTypeLabel">
      <label class="app-black--text mb-3 d-block bs-style-label">{{ $attrs.label }}</label>
    </template>
    <v-autocomplete
      :items="items"
      v-model="innerValue"
      v-bind="$attrs"
      :label="bsTypeLabel ? '' : $attrs.label"
      color="app-black"
      class="app-select"
      :menu-props="mProps"
      append-icon="mdi-chevron-down"
    >
      <template v-slot:label v-if="!!$slots.item">
        <slot name="label"></slot>
      </template>
      <template v-slot:item="attrs" v-if="!!$slots.item">
        <slot name="item" v-bind="attrs"></slot>
      </template>
      <template v-slot:selection="attrs" v-if="!!$slots.selection">
        <slot name="selection" v-bind="attrs"></slot>
      </template>

    </v-autocomplete>
  </div>
</template>

<script>
import {formInputMixins} from '@/mixins/formInputMixins'

export default {
  mixins: [formInputMixins],
  name: 'AppSelect',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Object],
      default: () => null
    },
    menuProps: {
      type: Object,
      default: () => ({})
    },
  },

  computed: {
    innerValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    mProps() {
      return {
        ...{
          nudgeBottom: '0',
          closeOnClick: true,
          contentClass: 'app-select-list',
          maxHeight: 200
        },
        ...this.menuProps
      }
    }
  }
}
</script>

<style lang="scss">
.app-select-list .v-list-item--active {
  color: #2E3038 !important;
}
/*.app-select{
  &.v-text-field--outlined {
    fieldset {
      border: 1px solid #000000;
    }
    &.v-input--is-dirty {
      fieldset {
        border: 2px solid #077D60;
      }

    }
    &.v-input--is-focused {
      fieldset {

      }
    }
    &.v-input--dense:not(.v-text-field--solo) {
      .v-label {
        top: 12px;
      }
      .v-input__append-inner {
        margin-top: 13px!important;
      }
    }
  }
  !*.v-select__selection {
    font-size: 18px;
  }*!
}
.app-select-list {
  overflow-y: auto;
  .v-list {
    margin: 0;
    padding: 0;
    .v-list-item {
      border: 1px solid #000000;
      &:hover {
        color: #000000!important;
        &:before {
          opacity: .5;
          background-color: #B9DCCB;
          color: #000000!important
        }
      }
    }
  }
}*/

</style>
